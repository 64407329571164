import React from 'react';
import { ReactTableWidget } from '@eeacms/volto-react-table-widget';

const ItemSchema = () => ({
  title: 'Datuak',
  properties: {
    herria: {
      title: 'Herria',
      type: 'string',
    },
    urtea: {
      title: 'Urtea',
      type: 'string',
    },
    mota: {
      title: 'Mota',
      type: 'string',
    },
    month1: {
      title: 'Urtarrila',
      type: 'int',
    },
    month2: {
      title: 'Otsaila',
      type: 'number',
    },
    month3: {
      title: 'Martxoa',
      type: 'number',
    },
    month4: {
      title: 'Apirila',
      type: 'number',
    },
    month5: {
      title: 'Maiatza',
      type: 'number',
    },
    month6: {
      title: 'Ekaina',
      type: 'number',
    },
    month7: {
      title: 'Uztaila',
      type: 'number',
    },
    month8: {
      title: 'Abuztua',
      type: 'number',
    },
    month9: {
      title: 'Iraila',
      type: 'number',
    },
    month10: {
      title: 'Urria',
      type: 'number',
    },
    month11: {
      title: 'Azaroa',
      type: 'number',
    },
    month12: {
      title: 'Abendua',
      type: 'number',
    },
  },
  fieldsets: [
    {
      id: 'default',
      title: 'Datuak',
      fields: [
        'herria',
        'urtea',
        'mota',
        'month1',
        'month2',
        'month3',
        'month4',
        'month5',
        'month6',
        'month7',
        'month8',
        'month9',
        'month10',
        'month11',
        'month12',
      ],
    },
  ],
  required: [],
});

const GrafikaDatuakTableWidget = (props) => {
  return (
    <ReactTableWidget
      schema={ItemSchema()}
      {...props}
      csvexport={true}
      csvimport={true}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default GrafikaDatuakTableWidget;
