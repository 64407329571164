/**
 * NewsItemView view component.
 * @module components/Views/ChartView
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid, Container, Image, Segment, Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { hasBlocksData } from '@plone/volto/helpers';
import moment from 'moment';
import messages from './messages.js';
import StringToHTML from 'volto-debabarrena/components/Utils/StringToHTML';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';

import {
  objSort,
  colors_definition,
  transparentize,
  random_rgba,
} from './utils.js';
/**
 * NewsItemView view component class.
 * @function ChartView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const ChartView = ({ content }) => {
  //ordered items
  const intl = useIntl();
  const currentYear = useSelector((state) => state.content.data.current_year);

  const data_copy = [...content.chart_data.items];
  const ordered_chart_data_items = objSort(
    data_copy,
    'urtea',
    'herria',
    'mota',
  );
  const herriak_list = [
    ...new Set(
      ordered_chart_data_items.map(({ herria }) => !!herria && herria),
    ),
  ];
  /*** Debabarrena lehenengoa agertzeko*/
  var first = 'Debabarrena';
  herriak_list.sort(function (x, y) {
    return x === first ? -1 : y === first ? 1 : 0;
  });
  const urteak_list = [
    ...new Set(ordered_chart_data_items.map(({ urtea }) => !!urtea && urtea)),
  ];
  const motak_list = [
    ...new Set(ordered_chart_data_items.map(({ mota }) => !!mota && mota)),
  ];
  const month1_list = [
    ...new Set(
      ordered_chart_data_items.map(({ month1 }) => !!month1 && month1),
    ),
  ];
  const month2_list = [
    ...new Set(
      ordered_chart_data_items.map(({ month2 }) => !!month2 && month2),
    ),
  ];
  const month3_list = [
    ...new Set(
      ordered_chart_data_items.map(({ month3 }) => !!month3 && month3),
    ),
  ];
  const month4_list = [
    ...new Set(
      ordered_chart_data_items.map(({ month4 }) => !!month4 && month4),
    ),
  ];
  const month5_list = [
    ...new Set(
      ordered_chart_data_items.map(({ month5 }) => !!month5 && month5),
    ),
  ];
  const month6_list = [
    ...new Set(
      ordered_chart_data_items.map(({ month6 }) => !!month6 && month6),
    ),
  ];
  const month7_list = [
    ...new Set(
      ordered_chart_data_items.map(({ month7 }) => !!month7 && month7),
    ),
  ];
  const month8_list = [
    ...new Set(
      ordered_chart_data_items.map(({ month8 }) => !!month8 && month8),
    ),
  ];
  const month9_list = [
    ...new Set(
      ordered_chart_data_items.map(({ month9 }) => !!month9 && month9),
    ),
  ];
  const month10_list = [
    ...new Set(
      ordered_chart_data_items.map(({ month10 }) => !!month10 && month10),
    ),
  ];
  const month11_list = [
    ...new Set(
      ordered_chart_data_items.map(({ month11 }) => !!month11 && month11),
    ),
  ];
  const month12_list = [
    ...new Set(
      ordered_chart_data_items.map(({ month12 }) => !!month12 && month12),
    ),
  ];
  !herriak_list[herriak_list.length - 1] && herriak_list.splice(-1, 1);
  !motak_list[motak_list.length - 1] && motak_list.splice(-1, 1);

  const hilabete_list = [];
  month1_list[0] && hilabete_list.push(intl.formatMessage(messages.january));
  month2_list[0] && hilabete_list.push(intl.formatMessage(messages.february));
  month3_list[0] && hilabete_list.push(intl.formatMessage(messages.march));
  month4_list[0] && hilabete_list.push(intl.formatMessage(messages.April));
  month5_list[0] && hilabete_list.push(intl.formatMessage(messages.may));
  month6_list[0] && hilabete_list.push(intl.formatMessage(messages.june));
  month7_list[0] && hilabete_list.push(intl.formatMessage(messages.july));
  month8_list[0] && hilabete_list.push(intl.formatMessage(messages.august));
  month9_list[0] && hilabete_list.push(intl.formatMessage(messages.september));
  month10_list[0] && hilabete_list.push(intl.formatMessage(messages.october));
  month11_list[0] && hilabete_list.push(intl.formatMessage(messages.november));
  month12_list[0] && hilabete_list.push(intl.formatMessage(messages.december));

  let primary_filters;
  !currentYear
    ? // aurreko urteetako fitroak
      (primary_filters = [
        {
          id: 0,
          key: 'herria',
          datasetkey: 'mota',
          title: intl.formatMessage(messages.byTown),
          secondary_filter: herriak_list,
          labels: urteak_list,
          datasets: motak_list,
        },
        {
          id: 1,
          key: 'mota',
          datasetkey: 'herria',
          title: intl.formatMessage(messages.byFraction),
          secondary_filter: motak_list,
          labels: urteak_list,
          datasets: herriak_list,
        },
        {
          id: 2,
          key: 'urtea',
          datasetkey: 'mota',
          title: intl.formatMessage(messages.yearly),
          secondary_filter: urteak_list,
          labels: herriak_list,
          datasets: motak_list,
        },
      ])
    : //azken urteko filtroak
      (primary_filters = [
        {
          id: 0,
          key: 'herria',
          datasetkey: 'mota',
          title: intl.formatMessage(messages.monthly),
          secondary_filter: herriak_list,
          labels: hilabete_list,
          datasets: motak_list,
        },
        {
          id: 1,
          key: 'mota',
          datasetkey: 'herria',
          title: intl.formatMessage(messages.byFraction),
          secondary_filter: motak_list,
          labels: hilabete_list,
          datasets: herriak_list,
        },
      ]);
  let lang = useSelector((state) => state.intl.locale);
  const [primary_filter, setprimaryFilter] = useState(
    primary_filters.filter((pf) => pf.id === 0)[0],
  );
  const [secondary_filter, setsecondaryFilter] = useState(
    primary_filter.secondary_filter[0],
  );
  const labels = primary_filter.labels;
  const datasets = primary_filter.datasets;
  //Debabarrena lehenengo urtekako grafikoan
  ordered_chart_data_items
    .sort(function (x, y) {
      return x.herria == first ? -1 : y.herria == first ? 1 : 0;
    })
    .sort((a, b) => {
      return a.urtea < b.urtea ? -1 : a.urtea > b.urtea ? 1 : 0;
    });
  //datset aurreko urtekoak

  const datasets_data = datasets.map((dataset) => {
    const random_color_value = random_rgba();
    return {
      label: dataset,
      data: ordered_chart_data_items
        .filter(
          (item) =>
            item[primary_filter.datasetkey] === dataset &&
            item[primary_filter.key] === secondary_filter,
        )
        .map((item) => {
          return (
            +item.month1 +
            +item.month2 +
            +item.month3 +
            +item.month4 +
            +item.month5 +
            +item.month6 +
            +item.month7 +
            +item.month8 +
            +item.month9 +
            +item.month10 +
            +item.month11 +
            +item.month12
          );
        }),
      backgroundColor: colors_definition[dataset]
        ? colors_definition[dataset]
        : random_color_value,
      borderColor: colors_definition[dataset]
        ? colors_definition[dataset]
        : random_color_value,
      stack:
        dataset === 'Resto' || dataset === 'Errefusa'
          ? 'Errefusa'
          : herriak_list.includes(dataset)
          ? dataset
          : 'Besteak',
    };
  });
  //dataset azken urtea
  const datasets_data_azken_urtea = datasets.map((dataset) => {
    const random_color_value = random_rgba();
    return {
      label: dataset,
      data: [
        ordered_chart_data_items
          .filter(
            (item) =>
              item[primary_filter.datasetkey] === dataset &&
              item[primary_filter.key] === secondary_filter,
          )
          .map((item) => Number(item.month1)),
        ordered_chart_data_items
          .filter(
            (item) =>
              item[primary_filter.datasetkey] === dataset &&
              item[primary_filter.key] === secondary_filter,
          )
          .map((item) => Number(item.month2)),
        ordered_chart_data_items
          .filter(
            (item) =>
              item[primary_filter.datasetkey] === dataset &&
              item[primary_filter.key] === secondary_filter,
          )
          .map((item) => Number(item.month3)),
        ordered_chart_data_items
          .filter(
            (item) =>
              item[primary_filter.datasetkey] === dataset &&
              item[primary_filter.key] === secondary_filter,
          )
          .map((item) => Number(item.month4)),
        ordered_chart_data_items
          .filter(
            (item) =>
              item[primary_filter.datasetkey] === dataset &&
              item[primary_filter.key] === secondary_filter,
          )
          .map((item) => Number(item.month5)),
        ordered_chart_data_items
          .filter(
            (item) =>
              item[primary_filter.datasetkey] === dataset &&
              item[primary_filter.key] === secondary_filter,
          )
          .map((item) => Number(item.month6)),
        ordered_chart_data_items
          .filter(
            (item) =>
              item[primary_filter.datasetkey] === dataset &&
              item[primary_filter.key] === secondary_filter,
          )
          .map((item) => Number(item.month7)),
        ordered_chart_data_items
          .filter(
            (item) =>
              item[primary_filter.datasetkey] === dataset &&
              item[primary_filter.key] === secondary_filter,
          )
          .map((item) => Number(item.month8)),
        ordered_chart_data_items
          .filter(
            (item) =>
              item[primary_filter.datasetkey] === dataset &&
              item[primary_filter.key] === secondary_filter,
          )
          .map((item) => Number(item.month9)),
        ordered_chart_data_items
          .filter(
            (item) =>
              item[primary_filter.datasetkey] === dataset &&
              item[primary_filter.key] === secondary_filter,
          )
          .map((item) => Number(item.month10)),
        ordered_chart_data_items
          .filter(
            (item) =>
              item[primary_filter.datasetkey] === dataset &&
              item[primary_filter.key] === secondary_filter,
          )
          .map((item) => Number(item.month11)),
        ordered_chart_data_items
          .filter(
            (item) =>
              item[primary_filter.datasetkey] === dataset &&
              item[primary_filter.key] === secondary_filter,
          )
          .map((item) => Number(item.month12)),
      ],

      backgroundColor: colors_definition[dataset]
        ? colors_definition[dataset]
        : random_color_value,
      borderColor: colors_definition[dataset]
        ? colors_definition[dataset]
        : random_color_value,
      stack:
        dataset === 'Resto' || dataset === 'Errefusa'
          ? 'Errefusa'
          : herriak_list.includes(dataset)
          ? dataset
          : 'Besteak',
    };
  });
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: secondary_filter,
        font: {
          size: 30,
        },
      },

      tooltip: {
        callbacks: {
          label: function (context) {
            let total = 0;
            let label;
            !currentYear
              ? data.datasets.map(
                  (item) => (total += item.data[context.dataIndex]),
                )
              : dataAzkenUrtea.datasets.map(
                  (item) => (total += item.data[context.dataIndex][0]),
                );
            !currentYear
              ? (label =
                  context.dataset.label +
                  ': ' +
                  ((context.raw / total) * 100).toFixed(2) +
                  '%')
              : (label =
                  context.dataset.label +
                  ': ' +
                  ((context.raw[0] / Number(total)) * 100).toFixed(2) +
                  '%');
            return label;
          },
        },
      },
    },
  };
  const frakzioOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: secondary_filter,
        font: {
          size: 30,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label + ': ' + context.raw;

            return label;
          },
        },
      },
    },
  };
  const data = {
    labels,
    datasets: datasets_data,
  };
  const dataAzkenUrtea = {
    labels,
    datasets: datasets_data_azken_urtea,
  };
  const ChangePrimaryFilter = (value) => {
    setprimaryFilter(value);
  };

  const ChangeSecondaryFilter = (value) => {
    setsecondaryFilter(value);
  };

  React.useEffect(() => {
    setsecondaryFilter(primary_filter.secondary_filter[0]);
  }, [primary_filter]);

  return (
    <Container>
      {content.title && (
        <h1 className="documentFirstHeading header-centered">
          {content.title}
        </h1>
      )}
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      <Segment className="main-filters" basic padded>
        <Button.Group>
          {primary_filters.length > 0 &&
            primary_filters?.map((pf, index) => (
              <Button
                size="huge"
                color={pf.id === primary_filter.id ? 'primary' : 'secondary'}
                key={index}
                onClick={() => ChangePrimaryFilter(pf)}
              >
                {pf.title}
              </Button>
            ))}
        </Button.Group>
      </Segment>
      <Segment className="secondary-filters" basic padded>
        <Button.Group>
          {primary_filter?.secondary_filter?.map((secondaryfilter, index) => (
            <Button
              size="big"
              color={
                secondaryfilter === secondary_filter ? 'primary' : 'secondary'
              }
              key={index}
              onClick={() => ChangeSecondaryFilter(secondaryfilter)}
            >
              {secondaryfilter}
            </Button>
          ))}
        </Button.Group>
      </Segment>
      {!currentYear &&
        (primary_filter.key === 'mota' ? (
          <Bar options={frakzioOptions} data={data} />
        ) : (
          <Bar options={options} data={data} />
        ))}
      {currentYear &&
        (primary_filter.key === 'mota' ? (
          <Bar options={frakzioOptions} data={dataAzkenUrtea} />
        ) : (
          <Bar options={options} data={dataAzkenUrtea} />
        ))}
      <StringToHTML string={content?.text?.data} />
      {hasBlocksData(content) && <RenderBlocks content={content} />}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ChartView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default ChartView;
