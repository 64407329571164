import { defineMessages, useIntl } from 'react-intl';

export const HomeFeaturedSchema = (config, hasButton, hasLocationInfo) => {
  const variationsConfig =
    config.blocks.blocksConfig['homeFeatured'].variations;
  const variations = Object.keys(variationsConfig).map((variation) => [
    variationsConfig[variation].id,
    variationsConfig[variation].title,
  ]);
  const buttonVarioationConfig =
    config.blocks.blocksConfig['homeFeatured'].buttonVariations;
  const buttonVariations = Object.keys(buttonVarioationConfig).map(
    (variation) => [
      buttonVarioationConfig[variation].id,
      buttonVarioationConfig[variation].title,
    ],
  );
  const intl = useIntl();
  const messages = defineMessages({
    variation: {
      id: 'Variation',
      defaultMessage: 'Variation',
    },
    title: {
      id: 'Title',
      defaultMessage: 'Title',
    },
    featuredTitle: {
      id: 'featuredTitle',
      defaultMessage: 'Featured title',
    },
    description: {
      id: 'Description',
      defaultMessage: 'Description',
    },
    featuredDescription: {
      id: 'featuredDescription',
      defaultMessage: 'Featured description',
    },
    linkURL: {
      id: 'linkURL',
      defaultMessage: 'Link URL',
    },
    linkDescription: {
      id: 'linkDescription',
      defaultMessage: 'Select site content or paste external url',
    },
    linkText: {
      id: 'linkText',
      defaultMessage: 'link text',
    },
    buttonLinkText: {
      id: 'buttonLinkText',
      defaultMessage: 'Button link text',
    },
    buttonVariation: {
      id: 'buttonVariation',
      defaultMessage: 'Button variation',
    },
  });
  return {
    title: 'Card container',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'variation',
          'title',
          'description',
          'link',
          'linktext',
          'buttonVariation',
        ],
      },
    ],
    properties: {
      variation: {
        title: intl.formatMessage(messages.variation),
        type: 'array',
        choices: [...variations],
      },
      title: {
        title: intl.formatMessage(messages.title),
        description: intl.formatMessage(messages.featuredTitle),
        type: 'string',
      },
      description: {
        title: intl.formatMessage(messages.description),
        description: intl.formatMessage(messages.featuredDescription),
        widget: 'richtext',
      },
      link: {
        title: intl.formatMessage(messages.linkURL),
        description: intl.formatMessage(messages.linkDescription),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description', '@type', '@id'],
        allowExternals: true,
      },
      linktext: {
        title: intl.formatMessage(messages.linkText),
        description: intl.formatMessage(messages.linkDescription),
        type: 'string',
      },
      buttonVariation: {
        title: intl.formatMessage(messages.buttonVariation),
        type: 'array',
        choices: [...buttonVariations],
      },
    },
    required: [],
  };
};
