import customBlocks from 'volto-debabarrena/components/Blocks/customBlocks';
import NewsItemView from './customizations/volto/components/theme/View/NewsItemView';
import ChartView from './components/Views/ChartView';
import GrafikaDatuakTableWidget from './components/Widgets/GrafikaDatuakTableWidget';
import ContractView from '@codesyntax/volto-publiccontracts-theme/Components/Views/ContractView';
//Blocks to hide on the editor
const restrictedBlocks = [
  'dataqueryfilter',
  'data_connected_embed',
  'custom_connected_block',
  'dottedTableChart',
  'simpleDataConnectedTable',
  'conditionalDataBlock',
  'countryFlag',
  'metadata',
  'metadataSection',
  'html',
];
const applyConfig = (config) => {
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      'News Item': NewsItemView,
      Chart: ChartView,
      Contract: ContractView,
    },
  };
  config.blocks = {
    ...config.blocks,
    blocksConfig: { ...customBlocks(config, restrictedBlocks) },
    groupBlocksOrder: [...config.blocks.groupBlocksOrder],
  };

  config.widgets.widget = {
    ...config.widgets.widget,
  };
  config.widgets.id = {
    ...config.widgets.id,
    chart_data: GrafikaDatuakTableWidget,
  };
  config.settings = {
    ...config.settings,
    cookiebotDomainGroupId: 'fa47c92a-1319-405d-9964-0f84f511ba26',
    nonContentRoutes: [...config.settings.nonContentRoutes],
    isMultilingual: true,
    supportedLanguages: ['eu', 'es'],
    defaultLanguage: 'eu',
    pluggableStylesBlocksBlacklist: restrictedBlocks,
    registry: {
      ...config.settings.registry,
    },
    showTags: false,
    pictureOptions: {
      grid: [
        { media: '(min-width: 768px)', image: 'preview' },
        { media: '(max-width: 767px)', image: 'large' },
      ],
      newsitem: [
        { media: '(min-width: 1200px)', image: 'larger' },
        { media: '(min-width: 992px) and (max-width: 1199px)', image: 'large' },
        { media: '(max-width: 991px)', image: 'teaser' },
      ],
    },
  };

  config.addonRoutes = [...config.addonRoutes];
  config.addonReducers = {
    ...config.addonReducers,
  };
  return config;
};
export default applyConfig;
