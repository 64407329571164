/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Icon, List, Segment, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { Logo } from '@plone/volto/components';
import footerLogo from 'volto-debabarrena/../theme/site/assets/images/logo-footer.svg';
import puntuMorea from 'volto-debabarrena/../theme/site/assets/images/puntu-morea.png';
import messages from 'volto-debabarrena/components/Utils/overrideMessages.js';
import { useGoogleAnalytics } from 'volto-google-analytics';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  useGoogleAnalytics();

  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);
  return (
    <>
      <Segment
        role="contentinfo"
        vertical
        padded
        inverted
        color="grey"
        textAlign="center"
        id="footer"
        className="colored-footer"
      >
        <div className="footer-text">
          <Image
            bordered
            centered
            src={footerLogo}
            alt="Debabarrena mankomunitatea"
            title="Debabarrenako mankomunitatea"
          />

          <h4>{intl.formatMessage(messages.address)}</h4>
        </div>
        <div className="footer-list">
          <List horizontal>
            <div role="listitem" className="item">
              <h4>{intl.formatMessage(messages.phone)}</h4>
            </div>
            <div role="listitem" className="item">
              {' | '}
            </div>
            <div role="listitem" className="item">
              <h4>{intl.formatMessage(messages.fax)}</h4>
            </div>
            <div role="listitem" className="item">
              {' | '}
            </div>
            <div role="listitem" className="item">
              <h4>
                <a href="mailto:debabarrena@debabarrena.eus">
                  {intl.formatMessage(messages.email)}
                </a>
              </h4>
            </div>
          </List>
        </div>
        <div className="footer-icon-list">
          <List horizontal>
            <div role="listitem" className="item">
              <a href={'https://www.facebook.com/debabarrena'}>
                <Icon circular inverted name="facebook f" />
              </a>
            </div>
            <div role="listitem" className="item">
              <a href={'https://twitter.com/infodebabarrena'}>
                <Icon circular inverted name="twitter" />
              </a>
            </div>
            <div role="listitem" className="item">
              <a href={`https://www.instagram.com/debabarrena.mankomunitatea`}>
                <Icon circular inverted name="instagram" />
              </a>
            </div>
          </List>
        </div>
      </Segment>
      <Segment basic vertical padded textAlign="center">
        <List horizontal className="footer-item-list">
          <div role="listitem" className="item">
            <Link
              className="item"
              to={`/${intl.formatMessage(messages.privacy_policy_link)}`}
            >
              {intl.formatMessage(messages.privacy_policy)}
            </Link>
          </div>
          <div role="listitem" className="item">
            <Link
              className="item"
              to={`/${intl.formatMessage(messages.cookie_link)}`}
            >
              {intl.formatMessage(messages.cookie)}
            </Link>
          </div>
        </List>
      </Segment>
      <Segment basic vertical padded textAlign="center">
        <Image
          // bordered
          centered
          src={puntuMorea}
          alt="Puntu morea"
          title="Puntu morea"
        />
      </Segment>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
