import React from 'react';
import DebabarrenaButton from './DebabarrenaButton';

const DebabarrenaButtonBlockView = (props) => {
  const { data } = props;
  return (
    <div>
      <DebabarrenaButton
        basic={data?.basic === true ? true : false}
        variation={data?.variation}
        size={data?.size}
        link={'' + data?.link?.[0]?.['@id']}
        item={data?.link?.[0]}
      >
        {data.title || 'Text example...'}
      </DebabarrenaButton>
    </div>
  );
};

export default DebabarrenaButtonBlockView;
