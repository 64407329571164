import React from 'react';
import HomeFeaturedImageEditor from './HomeFeaturedImageEditor';
import Featured from 'volto-debabarrena/components/Featured/Featured';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
const ImageAndLinkView = (props) => {
  const { data, isEditMode } = props;
  const flattern_url = flattenToAppURL(data?.link?.[0]?.['@id']);
  return (
    <>
      {isEditMode && <HomeFeaturedImageEditor {...props} />}
      <Featured
        title={data.title}
        imageurl={data?.image?.url + '/@@images/image'}
        url={!isEditMode ? flattern_url : ''}
        mode="image-and-link"
      ></Featured>
    </>
  );
};

export default ImageAndLinkView;
