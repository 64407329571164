/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { find, map } from 'lodash';

import {
  Helmet,
  langmap,
  flattenToAppURL,
  normalizeLanguageName,
} from '@plone/volto/helpers';

import config from '@plone/volto/registry';

import { changeLanguage } from '@plone/volto/actions';

import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  switchLanguageTo: {
    id: 'Switch to',
    defaultMessage: 'Switch to',
  },
});

const LanguageSelector = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.intl.locale);
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );

  const { settings } = config;
  return settings.isMultilingual ? (
    <div className="language-selector">
      <Dropdown
        text={langmap[currentLang].nativeName.toLowerCase()}
        className="item"
      >
        <Dropdown.Menu>
          {map(settings.supportedLanguages, (lang) => {
            const translation = find(translations, { language: lang });
            const nativeName = langmap[lang].nativeName.toLowerCase();
            const iscurrent = currentLang === lang;
            return (
              !iscurrent && (
                <Dropdown.Item key={`language-selector-${lang}`}>
                  <Link
                    aria-label={`${intl.formatMessage(
                      messages.switchLanguageTo,
                    )} ${langmap[lang].nativeName.toLowerCase()}`}
                    className={cx({ selected: iscurrent })}
                    to={
                      translation
                        ? flattenToAppURL(translation['@id'])
                        : `/${lang}`
                    }
                    title={nativeName}
                    onClick={() => {
                      props.onClickAction();
                      if (config.settings.supportedLanguages.includes(lang)) {
                        const langFileName = normalizeLanguageName(lang);
                        import('~/../locales/' + langFileName + '.json').then(
                          (locale) => {
                            dispatch(changeLanguage(lang, locale.default));
                          },
                        );
                      }
                    }}
                  >
                    {nativeName}
                  </Link>
                </Dropdown.Item>
              )
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  ) : (
    <Helmet>
      <html lang={settings.defaultLanguage} />
    </Helmet>
  );
};

LanguageSelector.propTypes = {
  onClickAction: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClickAction: () => {},
};

export default LanguageSelector;
