import React from 'react';
import PropTypes from 'prop-types';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import { Image } from 'semantic-ui-react';
import './styles.less';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import { flattenToAppURL } from '@plone/volto/helpers';

const LightGalleryListing = ({ items }) => {
  return (
    <>
      <div>
        {items[0]['@type'] === 'Image' && (
          <LightGallery plugins={[lgZoom]} mode="lg-fade">
            {items.map((item, index) => (
              <a
                key={index}
                className="gallery-item"
                href={flattenToAppURL(item?.image?.download)}
                data-sub-html={item.description}
                data-src={flattenToAppURL(item?.image?.scales?.huge?.download)}
              >
                <Image
                  src={flattenToAppURL(item?.image?.scales?.large?.download)}
                  className="img-responsive"
                  alt={item.description}
                />
              </a>
            ))}
          </LightGallery>
        )}
      </div>
    </>
  );
};
LightGalleryListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default LightGalleryListing;
