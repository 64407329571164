import { defineMessages } from 'react-intl';
const messages = defineMessages({
  yearly: {
    id: 'Yearly',
    defaultMessage: 'Yearly',
  },
  monthly: {
    id: 'Monthly',
    defaultMessage: 'Monthly',
  },
  byFraction: {
    id: 'By fraction',
    defaultMessage: 'By Fraction',
  },
  byTown: {
    id: 'By town',
    defaultMessage: 'By town',
  },
  glass: {
    id: 'Glass',
    defaultMessage: 'Glass',
  },
  others: {
    id: 'Others',
    defaultMessage: 'Others',
  },
  rejection: {
    id: 'Rejection',
    defaultMessage: 'Rejection',
  },
  plastics: {
    id: 'Plastics',
    defaultMessage: 'Plastics',
  },
  organic: {
    id: 'Organic',
    defaultMessage: 'Organic',
  },
  paperAndCarboard: {
    id: 'Paper and cardboard',
    defaultMessage: 'Paper and cardboard',
  },
  january: {
    id: 'January',
    defaultMessage: 'January',
  },
  february: {
    id: 'February',
    defaultMessage: 'February',
  },
  march: {
    id: 'March',
    defaultMessage: 'March',
  },
  April: {
    id: 'April',
    defaultMessage: 'April',
  },
  may: {
    id: 'May',
    defaultMessage: 'May',
  },
  june: {
    id: 'June',
    defaultMessage: 'June',
  },
  july: {
    id: 'July',
    defaultMessage: 'July',
  },
  august: {
    id: 'August',
    defaultMessage: 'August',
  },
  september: {
    id: 'September',
    defaultMessage: 'September',
  },
  october: {
    id: 'October',
    defaultMessage: 'October',
  },
  november: {
    id: 'November',
    defaultMessage: 'November',
  },
  december: {
    id: 'December',
    defaultMessage: 'December',
  },
});
export default messages;
