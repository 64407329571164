import React from 'react';
import DebabarrenaButton from './DebabarrenaButton';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import { DebabarrenaButtonSchema } from 'volto-debabarrena/components/Blocks/DebabarrenaButtonBlock/DebabarrenaButtonSchema';
import config from '@plone/volto/registry';

const DebabarrenaButtonBlockEdit = (props) => {
  const { block, data, onChangeBlock, selected } = props;
  return (
    <>
      <DebabarrenaButton
        variation={data?.variation}
        size={data?.size}
        link={''}
      >
        {data.title || 'Text example...'}
      </DebabarrenaButton>

      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={DebabarrenaButtonSchema(
            config,
            data.hasButton,
            data.hasLocationInfo,
          )}
          title="Debabarrena Button block"
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
          block={block}
        />
      </SidebarPortal>
    </>
  );
};

export default DebabarrenaButtonBlockEdit;
