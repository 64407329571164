import React from 'react';
import HomeFeaturedImageEditor from './HomeFeaturedImageEditor';
import DebabarrenaButton from 'volto-debabarrena/components/Blocks/DebabarrenaButtonBlock/DebabarrenaButton';
import StringToHTML from 'volto-debabarrena/components/Utils/StringToHTML';

const CarouselFeaturedView = (props) => {
  const { data, isEditMode } = props;
  return (
    <div
      className="banner-top-main"
      style={{
        backgroundImage: `url(${data?.image?.url}/@@images/image)`,
      }}
    >
      {isEditMode && <HomeFeaturedImageEditor {...props} />}
      <div className="ui two column grid">
        <div className="row">
          <div className="ten wide column left floated"></div>
          <div className="six wide column right floated">
            <div className="banner-top-main-block-title">
              {data.title && <h2>{data?.title}</h2>}
              {data.description && (
                <h3>
                  <StringToHTML string={data?.description?.data} />{' '}
                </h3>
              )}
              <DebabarrenaButton
                as="a"
                size="large"
                link={isEditMode ? '' : '' + data?.link?.[0]?.['@id']}
                variation={data.buttonVariation}
              >
                {data.linktext}
              </DebabarrenaButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselFeaturedView;
