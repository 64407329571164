import React from 'react';
import ContextNavigationComponent from '@plone/volto/components/theme/Navigation/ContextNavigation';

const ContextNavigationBlockView = (props) => {
  return (
    <ContextNavigationComponent
      params={{ topLevel: 1, bottomLevel: 5 }}
      pathname={props.pathname}
    />
  );
};

export default ContextNavigationBlockView;
