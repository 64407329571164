import React from 'react';
import classNames from 'classnames';
import './featured.less';
import PropTypes from 'prop-types';
import Picture from 'volto-debabarrena/components/Picture/Picture';
import { UniversalLink } from '@plone/volto/components';
function Featured(props) {
  let {
    title = '',
    url = '',
    mode = 'image-and-link',
    imageurl = '',
    children,
  } = props;

  let buttonClass = classNames('home-featured-element', {
    'image-and-link': mode === 'image-and-link',
    'text-over-image': mode === 'text-over-image',
    'featured-button': mode === 'featured-button',
  });

  return (
    <div className={buttonClass}>
      {url ? (
        <>
          <UniversalLink href={url}>
            <Picture source="grid" imageBase={imageurl} alt={title}></Picture>
            <h3 className="featured-title">{title}</h3>
          </UniversalLink>
          {children}
        </>
      ) : (
        <>
          <Picture source="grid" imageBase={imageurl} alt={title}></Picture>
          <h3 className="featured-title">{title}</h3>
          {children}
        </>
      )}
    </div>
  );
}

Featured.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  mode: PropTypes.string,
  imageurl: PropTypes.string,
  children: PropTypes.node,
};
export default Featured;
