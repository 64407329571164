/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Navigation from '../Navigation/Navigation';
import { LanguageSelector, Logo } from '@plone/volto/components';
// import './header.less';
import messages from 'volto-debabarrena/components/Utils/overrideMessages.js';

function HeaderContent() {
  const intl = useIntl();
  return (
    <>
      <span className="computer large screen only">
        {intl.formatMessage(messages.schedule)}
      </span>{' '}
      <span className="top-phone">
        <Icon name="phone"></Icon>
        {intl.formatMessage(messages.phone)}
      </span>
      {' | '}
      <Icon name="envelope outline"></Icon>
      <a href="mailto:debabarrena@debabarrena.eus" className="top-email">
        {intl.formatMessage(messages.contact)}
      </a>
      <span className="computer large screen only">
        {' | '}
        <a href={'https://www.facebook.com/debabarrena.mankomunitatea'}>
          <Icon circular inverted name="facebook f"></Icon>
        </a>
        <a href={'https://twitter.com/debabarrena'}>
          <Icon circular inverted name="twitter" />
        </a>
        <a href={`https://www.instagram.com/debabarrena.mankomunitatea`}>
          <Icon circular inverted name="instagram" />
        </a>
        {' | '}
      </span>
    </>
  );
}

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */

  render() {
    return (
      <Segment role="banner">
        <div className="header">
          <div test>
            <div></div>
          </div>
          <div className="main-navigation">
            <div className="main-site-logo"></div>
            <div className="main-navigation-contact">
              <Segment basic textAlign="right">
                <HeaderContent />
                <span className="computer large screen only">
                  <LanguageSelector />
                </span>
              </Segment>
            </div>
            <div className="logo-nav-wrapper container ui">
              <div className="logo">
                <Segment basic>
                  <Logo />
                  <Navigation pathname={this.props.pathname} />{' '}
                </Segment>
              </div>
            </div>
          </div>
        </div>
      </Segment>
    );
  }
}

export default Header;
/* connect((state) => ({
  token: state.userSession.token,
  subsite: state.subsite?.data,
}))(Header);
 */
