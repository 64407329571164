export const DebabarrenaButtonSchema = (config, hasButton, hasLocationInfo) => {
  const variationsConfig =
    config.blocks.blocksConfig['debabarrenaButton'].variation;
  const variations = Object.keys(variationsConfig).map((variation) => [
    variationsConfig[variation].id,
    variationsConfig[variation].title,
  ]);
  const SizeConfig = config.blocks.blocksConfig['debabarrenaButton'].size;
  const size = Object.keys(SizeConfig).map((size) => [
    SizeConfig[size].id,
    SizeConfig[size].title,
  ]);

  return {
    title: 'Button',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['variation', 'title', 'link', 'size'],
      },
    ],
    properties: {
      variation: {
        title: 'Variation',
        type: 'array',
        choices: [...variations],
        required: true,
      },
      title: {
        title: 'Title',
        description: 'Featured title',
        type: 'string',
      },
      link: {
        title: 'link URL',
        description: 'Select site content or paste external url',
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description', '@type', '@id'],
        allowExternals: true,
      },
      size: {
        title: 'Size',
        type: 'array',
        choices: [...size],
        required: true,
      },
    },
    required: [],
  };
};
