import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';

const DebabarrenaButton = (props) => {
  let { variation, link, size, children, item } = props;
  switch (variation) {
    case 'primary':
      return (
        <div>
          <UniversalLink item={item} href={link}>
            <Button primary={true} size={size} as="a">
              {children}
            </Button>
          </UniversalLink>
        </div>
      );
    case 'secondary':
      return (
        <div>
          <UniversalLink item={item} href={link}>
            <Button secondary={true} size={size} as="a">
              {children}
            </Button>
          </UniversalLink>
        </div>
      );
    case 'dagigun':
      return (
        <div>
          <UniversalLink item={item} href={link}>
            <Button size={size} className={variation} as="a">
              {children}
            </Button>
          </UniversalLink>
        </div>
      );
    case 'basic':
      return (
        <div>
          <UniversalLink item={item} href={link}>
            <Button size={size} basic={true} as="a">
              {children}
            </Button>
          </UniversalLink>
        </div>
      );
    default:
      return (
        <div>
          <UniversalLink item={item} href={link}>
            <Button primary={true} size={size} as="a">
              {children}
            </Button>
          </UniversalLink>
        </div>
      );
  }
};

DebabarrenaButton.propTypes = {
  variation: PropTypes.string,
  link: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
};
export default DebabarrenaButton;
