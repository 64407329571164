import { defineMessages } from 'react-intl';

const messages = defineMessages({
  commonwealth: {
    id: 'Commonwealth',
    defaultMessage: 'Debabarrena commonwealth',
  },
  address: {
    id: 'Address',
    defaultMessage: 'Egigurentarren, 2 behea | 20600 Eibar, Gipuzkoa',
  },
  phone: {
    id: 'Phone',
    defaultMessage: '943 700 799',
  },
  fax: {
    id: 'Fax',
    defaultMessage: 'Fax: 943 200 798',
  },
  email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  privacy_policy: {
    id: 'Privacy_policy',
    defaultMessage: 'Privacy policy',
  },
  cookie: {
    id: 'Cookie',
    defaultMessage: 'Cookie policy',
  },
  schedule: {
    id: 'Schedule',
    defaultMessage: 'Schedule: From 9:00 to 14:00 Monday to Friday',
  },
  contact: {
    id: 'Contact',
    defaultMessage: 'Contact',
  },
  privacy_policy_link: {
    id: 'privacy_policy_link',
    defaultMessage: 'eu/pribatutasun-politika-orokorra',
  },
  cookie_link: {
    id: 'cookie_link',
    defaultMessage: 'eu/pribatutasun-politika-orokorra/cookieen-politika',
  },
  debabarrenaRegionalCommonwealth: {
    id: 'debabarrenaRegionalCommonwealth',
    defaultMessage: 'Debabarrena regional commonwealth',
  },
  site: {
    id: 'Site',
    defaultMessage: 'Site',
  },
});
export default messages;
