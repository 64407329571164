import colorLib from '@kurkle/color';
function objSort() {
  var args = arguments,
    array = args[0],
    case_sensitive,
    keys_length,
    key,
    desc,
    a,
    b,
    i;
  if (typeof arguments[arguments.length - 1] === 'boolean') {
    case_sensitive = arguments[arguments.length - 1];
    keys_length = arguments.length - 1;
  } else {
    case_sensitive = false;
    keys_length = arguments.length;
  }
  return array.sort(function (obj1, obj2) {
    for (i = 1; i < keys_length; i++) {
      key = args[i];
      if (typeof key !== 'string') {
        desc = key[1];
        key = key[0];
        a = obj1[args[i][0]];
        b = obj2[args[i][0]];
      } else {
        desc = false;
        a = obj1[args[i]];
        b = obj2[args[i]];
      }

      if (case_sensitive === false && typeof a === 'string') {
        a = a.toLowerCase();
        b = b?.toLowerCase();
      }

      if (!desc) {
        if (a < b) return -1;
        if (a > b) return 1;
      } else {
        if (a > b) return -1;
        if (a < b) return 1;
      }
    }
    return 0;
  });
} //end of objSort() function

const colors_definition = {
  Beira: 'rgb(22,142,27)',
  Vidrio: 'rgb(22,142,27)',
  'Papera eta kartoia': 'rgb(42,104,176)',
  'Papel y cartón': 'rgb(42,104,176)',
  Errefusa: 'rgb(154,160,166)',
  Resto: 'rgb(154,160,166)',
  'Ontzi arinak': 'rgb(255,232,22)',
  'Envases ligeros': 'rgb(255,232,22)',
  Besteak: 'rgb(239,113,22)',
  Otros: 'rgb(239,113,22)',
  Organikoa: 'rgb(170,104,43)',
  Biorresiduos: 'rgb(170,104,43)',
  Eibar: 'rgb(249,155,115)',
  Ermua: 'rgb(171,220,153)',
  Elgoibar: 'rgb(189,221,213)',
  Deba: 'rgb(175,218,242)',
  Mutriku: 'rgb(205,168,241)',
  Soraluze: 'rgb(255,136,207)',
  Mendaro: 'rgb(244,134,134)',
  Mallabia: 'rgb(241,189,87)',
  Debabarrena: 'rgb(255,232,22)',
};

function transparentize(value, opacity) {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}

function random_rgba() {
  var o = Math.round,
    r = Math.random,
    s = 255;
  return (
    'rgba(' +
    o(r() * s) +
    ',' +
    o(r() * s) +
    ',' +
    o(r() * s) +
    ',' +
    r().toFixed(1) +
    ')'
  );
}

export { objSort, colors_definition, transparentize, random_rgba };
