import navSVG from '@plone/volto/icons/nav.svg';
import homeBand from '@plone/volto/icons/image-wide.svg';
import DebabarrenaButtonBlockEdit from 'volto-debabarrena/components/Blocks/DebabarrenaButtonBlock/DebabarrenaButtonBlockEdit';
import DebabarrenaButtonBlockView from 'volto-debabarrena/components/Blocks/DebabarrenaButtonBlock/DebabarrenaButtonBlockView';
import { DebabarrenaButtonSchema } from 'volto-debabarrena/components/Blocks/DebabarrenaButtonBlock/DebabarrenaButtonSchema';
import HomeFeaturedBlockView from 'volto-debabarrena/components/Blocks/HomeFeaturedBlock/HomeFeaturedBlockView';
import HomeFeaturedBlockEdit from 'volto-debabarrena/components/Blocks/HomeFeaturedBlock/HomeFeaturedBlockEdit';
import { HomeFeaturedSchema } from 'volto-debabarrena/components/Blocks/HomeFeaturedBlock/HomeFeaturedSchema';
import CarouselFeaturedView from 'volto-debabarrena/components/Blocks/HomeFeaturedBlock/CarouselFeaturedView';
import ImageAndLinkView from 'volto-debabarrena/components/Blocks/HomeFeaturedBlock/ImageAndLinkView';
import TextOverImage from 'volto-debabarrena/components/Blocks/HomeFeaturedBlock/TextOverImage';
import FeaturedButton from 'volto-debabarrena/components/Blocks/HomeFeaturedBlock/HomeFeaturedButtonView';
import ContextNavigationBlockView from 'volto-debabarrena/components/Blocks/ContextNavigationBlock/ContextNavigationBlockView';
import ContextNavigationBlockEdit from 'volto-debabarrena/components/Blocks/ContextNavigationBlock/ContextNavigationBlockEdit';
import { TABS_BLOCK } from '@eeacms/volto-tabs-block/constants';
import {
  DefaultEdit,
  defaultSchema,
  TabsEdit,
  TabsView,
} from '@eeacms/volto-tabs-block/components';

import LightGalleryListing from 'volto-debabarrena/components/Blocks/LightGalleryListing/LightGalleryListing';
import { CarouselView } from 'volto-debabarrena/components/Blocks/CustomTemplates/VoltoTabsBlock';
import { ListingSubjectView } from 'volto-debabarrena/components/Blocks/CustomTemplates/VoltoListingBlock';
import { subjectSchema } from 'volto-debabarrena/components/Blocks/CustomTemplates/VoltoListingBlock';
import { advancedSchema } from '@codesyntax/volto-listingadvanced-variation/advancedSchema';
import AdvancedListingBlockTemplate from '@codesyntax/volto-listingadvanced-variation/AdvancedListingBlockTemplate';

const customBlocks = (config, restrictedBlocks) => {
  let restrictedBlocksTree = {};

  restrictedBlocks.forEach(
    (rBlock) =>
      (restrictedBlocksTree[rBlock] = {
        ...config.blocks.blocksConfig[rBlock],
        restricted: true,
      }),
  );
  return {
    ...config.blocks.blocksConfig,
    /* ...(config.blocks.blocksConfig.dataqueryfilter.restricted = true),
    ...(config.blocks.blocksConfig.data_connected_embed.restricted = true),
    ...(config.blocks.blocksConfig.custom_connected_block.restricted = true),
    ...(config.blocks.blocksConfig.dottedTableChart.restricted = true),
    ...(config.blocks.blocksConfig.simpleDataConnectedTable.restricted = true),
    ...(config.blocks.blocksConfig.conditionalDataBlock.restricted = true),
    ...(config.blocks.blocksConfig.countryFlag.restricted = true),
    ...(config.blocks.blocksConfig.metadata.restricted = true),
    ...(config.blocks.blocksConfig.metadataSection.restricted = true),
    ...(config.blocks.blocksConfig.html.restricted = true), */
    // dataqueryfilter: {
    //   ...config.blocks.blocksConfig.dataqueryfilter,
    //   restricted: true,
    // },
    ...restrictedBlocksTree,
    [TABS_BLOCK]: {
      ...config.blocks.blocksConfig[TABS_BLOCK],
      edit: TabsEdit,
      view: TabsView,
      templates: {
        ...config.blocks.blocksConfig[TABS_BLOCK]?.templates,
        DebabarrenaCarousel: {
          title: 'Debabarrena Carousel',
          edit: DefaultEdit,
          view: CarouselView,
          schema: defaultSchema,
        },
      },
    },

    listing: {
      ...config.blocks.blocksConfig.listing,
      variations: [
        ...config.blocks.blocksConfig.listing.variations,
        {
          id: 'subject',
          title: 'Subject',
          template: ListingSubjectView,
          schemaEnhancer: subjectSchema,
        },
        {
          id: 'lightgallery',
          title: 'LightGallery',
          template: LightGalleryListing,
          fullobjects: true,
        },
        {
          id: 'pdfview',
          title: 'PdfView',
          template: AdvancedListingBlockTemplate,
          schemaEnhancer: advancedSchema,
        },
      ],
    },

    __grid: {
      ...config.blocks.blocksConfig.__grid,
      gridAllowedBlocks: [
        'teaser',
        'image',
        'listing',
        'slate',
        'text',
        'homeFeatured',
        'debabarrenaButton',
      ],
      maxNumberOfColumns: 16,
    },

    debabarrenaButton: {
      id: 'debabarrenaButton',
      title: 'Debabarrena Button',
      icon: navSVG,
      group: 'common',
      view: DebabarrenaButtonBlockView,
      edit: DebabarrenaButtonBlockEdit,
      schema: DebabarrenaButtonSchema,
      restricted: false, // If the block is restricted, it won't show in the chooser
      mostUsed: false, // A meta group `most used`, appearing at the top of the chooser
      blockHasOwnFocusManagement: false, // Set this to true if the block manages its own focus
      sidebarTab: 1, // The sidebar tab you want to be selected when selecting the block
      security: {
        addPermission: [], // Future proof (not implemented yet) add user permission role(s)
        view: [], // Future proof (not implemented yet) view user role(s)
      },
      variation: [
        {
          id: 'primary',
          isDefault: true,
          title: 'Primary',
        },
        {
          id: 'secondary',
          isDefault: false,
          title: 'Secondary',
        },
        {
          id: 'basic',
          isDefault: false,
          title: 'Basic',
        },
      ],
      size: [
        {
          id: 'large',
          isDefault: true,
          title: 'Large',
        },
        {
          id: 'medium',
          isDefault: false,
          title: 'Medium',
        },
        {
          id: 'small',
          isDefault: false,
          title: 'Small',
        },
        {
          id: 'tiny',
          isDefault: false,
          title: 'Tiny',
        },
      ],
    },
    homeFeatured: {
      id: 'homeFeatured', // The name (id) of the block
      title: 'Home Featured', // The display name of the block
      icon: homeBand, // The icon used in the block chooser
      group: 'common', // The group (blocks can be grouped, displayed in the chooser)
      view: HomeFeaturedBlockView, // The view mode component
      edit: HomeFeaturedBlockEdit, // The edit mode component
      schema: HomeFeaturedSchema,
      restricted: false, // If the block is restricted, it won't show in the chooser
      mostUsed: false, // A meta group `most used`, appearing at the top of the chooser
      blockHasOwnFocusManagement: false, // Set this to true if the block manages its own focus
      sidebarTab: 1, // The sidebar tab you want to be selected when selecting the block
      security: {
        addPermission: [], // Future proof (not implemented yet) add user permission role(s)
        view: [], // Future proof (not implemented yet) view user role(s)
      },
      variations: [
        {
          id: 'carousel',
          isDefault: false,
          title: 'Carousel',
          template: CarouselFeaturedView,
        },
        {
          id: 'imageandlink',
          isDefault: true,
          title: 'image&link',
          template: ImageAndLinkView,
        },
        /* {
          id: 'textoverimage',
          isDefault: true,
          title: 'TextOverImage',
          template: TextOverImage,
        }, */
        {
          id: 'button',
          isDefault: false,
          title: 'featuredWithButton',
          template: FeaturedButton,
        },
      ],
      buttonVariations: [
        {
          id: 'primary',
          isDefault: true,
          title: 'Primary',
        },
        {
          id: 'secondary',
          isDefault: false,
          title: 'Secondary',
        },
        {
          id: 'basic',
          isDefault: false,
          title: 'Basic',
        },
      ],
    },
    contextNavigation: {
      id: 'contextNavigation', // The name (id) of the block
      title: 'Context Navigation', // The display name of the block
      icon: navSVG, // The icon used in the block chooser
      group: 'common', // The group (blocks can be grouped, displayed in the chooser)
      view: ContextNavigationBlockView, // The view mode component
      edit: ContextNavigationBlockEdit, // The edit mode component
      restricted: false, // If the block is restricted, it won't show in the chooser
      mostUsed: false, // A meta group `most used`, appearing at the top of the chooser
      blockHasOwnFocusManagement: false, // Set this to true if the block manages its own focus
      sidebarTab: 0, // The sidebar tab you want to be selected when selecting the block
      security: {
        addPermission: [], // Future proof (not implemented yet) add user permission role(s)
        view: [], // Future proof (not implemented yet) view user role(s)
      },
    },
  };
};

export default customBlocks;
